// import translate from "google-translate-api";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../Layout/Header";
import SiderBar from "../Layout/SiderBar";
import RightContainer from "./RightContainer";
// import { translateText } from "./Translate";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import HttpApi from "i18next-http-backend";
import Languageoption from "./language-dropdown";
import { useSelector } from "react-redux";
var _ = require("lodash");

function Home() {
  const { t, i18n } = useTranslation();

  const handleClick = (e) => {
    console.log(e?.target?.value);
    localStorage.setItem("lang", e?.target?.value);
    i18next.changeLanguage(e?.target?.value);
  };

  const get_message = useSelector((state) =>
    state?.allapi?.get_message ? state?.allapi?.get_message : {}
  );

  console.log(1 + 2);
  const ab = [1, 2, 3, 4, 5, 6, 7];
  const abc = [{ name: "" }];
  const as = _.chunk(ab, 2);

  console.log(as);

  return (
    <div>
      <div id="root">
        {/* <!------Nav Menu Start------> */}

        <SiderBar />

        {/* 
      <!------Nav Menu End------>

      <!------Hedar Start------> */}

        <div className="leftContainer">
          <Header />

          <div className="main-area" style={{ paddingTop: "60px" }}>
            {/* <div className="p-3">
              {get_message?.sec && (
                <div
                  className={
                    get_message?.sec?.priority == "red"
                      ? "messagebox9"
                      : "messagebox2"
                  }
                >
                  <div class="collapseCard-text">
                    {get_message?.sec?.message}
                  </div>
                </div>
              )}
            </div> */}
            {get_message?.first && (
              <div className="collapseCard-container ">
                <div className="collapseCard   text-danger ">
                  <Link to="/refer-earn" style={{ textDecoration: "none" }}>
                    <div
                      className="collapseCard-body"
                      style={{
                        height: "64px",
                        opacity: "1",
                        transition: "height 0.3s ease 0s, opacity 0.3s ease 0s",
                      }}
                    >
                      <div className="collapseCard-text text-danger">
                        {get_message?.first?.message}
                      </div>
                      <picture className="collapseCard-closeIcon">
                        <img
                          className="position-relative"
                          src="../assest/image/right-arrow.png"
                          alt=""
                          width="18px"
                          height="18px"
                        />
                      </picture>
                    </div>
                  </Link>

                  {/* <div
                    className="collapseCard-header"
                    style={{ left: "22px", transition: "left 0.3s ease 0s" }}
                  >
                    <picture>
                      <img
                        height="10px"
                        width="14px"
                        src="../assest/image/global-ytPlayIcon.png"
                        alt=""
                      />
                    </picture>
                    <div className="collapseCard-title ms-1 mt-1">
                      Importent
                    </div>
                  </div> */}
                </div>
              </div>
            )}

            <section className="games-section p-3">
              <div className="d-flex align-items-center games-section-title">
                {/* Our Games */}
                {t("overgame")}
              </div>
              <div className="games-section-headline mt-2 mb-1">
                <div className="games-window">
                  <div className="gameCard-container">
                    <span className="blink text-danger d-block text-right">
                      ◉ LIVE
                    </span>

                    <Link to={"/ludo-Classic"} className="gameCard">
                      <picture className="gameCard-image">
                        <img
                          width="100%"
                          src="../assest/image/manualroomcode.png"
                          alt=""
                        />
                      </picture>
                      <div className="gameCard-title">Ludo Classic Manual</div>
                      <picture className="gameCard-icon">
                        <img
                          src="../assest/image/global-battleIconWhiteStroke.png"
                          alt=""
                        />
                      </picture>
                    </Link>

                    {/* <span className="blink text-danger d-block text-right">
                      ◉ LIVE
                    </span>
                    <Link to={"/ludo-Classic-auto"} className="gameCard">
                      <picture className="gameCard-image">
                        <img
                          width="100%"
                          src="../assest/image/autoroomcode.png"
                          alt=""
                        />
                      </picture>
                      <div className="gameCard-title">Ludo Classic Auto</div>
                      <picture className="gameCard-icon">
                        <img
                          src="../assest/image/global-battleIconWhiteStroke.png"
                          alt=""
                        />
                      </picture>
                    </Link> */}

                    {/* <span className="blink text-danger d-block text-right">
                      ◉ LIVE
                    </span>
                    <Link to={"/ludo-popular"} className="gameCard">
                      <picture className="gameCard-image">
                        <img width="100%" src="../assest/image/22.png" alt="" />
                      </picture>
                      <div className="gameCard-title">Ludo Popular</div>
                      <picture className="gameCard-icon">
                        <img
                          src="../assest/image/global-battleIconWhiteStroke.png"
                          alt=""
                        />
                      </picture>
                    </Link> */}
                    {/* <span className="blink text-danger d-block text-right">
                      ◉ Comming Soon
                    </span>

                    <a href={"#"} className="gameCard">
                      <picture className="gameCard-image">
                        <img
                          width="100%"
                          src="../assest/image/SNAKELADDER.png"
                          alt=""
                        />
                      </picture>
                      <div className="gameCard-title">SNAKE & LADDER</div>
                      <picture className="gameCard-icon">
                        <img
                          src="../assest/image/global-battleIconWhiteStroke.png"
                          alt=""
                        />
                      </picture>
                    </a> */}

                    {/* <span className="blink text-danger d-block text-right">
                      ◉ Comming Soon
                    </span>
                    <a href={"#"} className="gameCard">
                      <picture className="gameCard-image">
                        <img
                          width="100%"
                          src="../assest/image/teenpatti.png"
                          alt=""
                        />
                      </picture>
                      <div className="gameCard-title">Teen Patti</div>
                      <picture className="gameCard-icon">
                        <img
                          src="../assest/image/global-battleIconWhiteStroke.png"
                          alt=""
                        />
                      </picture>
                    </a> */}
                  </div>

                  <div className="gameCard-container">
                    {/* <span className="blink text-danger d-block text-right">
                      ◉ LIVE
                    </span>
                    <Link to={"/ludo-Classic-auto"} className="gameCard">
                      <picture className="gameCard-image">
                        <img
                          width="100%"
                          src="../assest/image/autoroomcode.png"
                          alt=""
                        />
                      </picture>
                      <div className="gameCard-title">Ludo Classic Auto</div>
                      <picture className="gameCard-icon">
                        <img
                          src="../assest/image/global-battleIconWhiteStroke.png"
                          alt=""
                        />
                      </picture>
                    </Link> */}

                    <span className="blink text-danger d-block text-right">
                      ◉ LIVE
                    </span>
                    <Link to={"/ludo-popular"} className="gameCard">
                      <picture className="gameCard-image">
                        <img width="100%" src="../assest/image/22.png" alt="" />
                      </picture>
                      <div className="gameCard-title">Ludo Popular</div>
                      <picture className="gameCard-icon">
                        <img
                          src="../assest/image/global-battleIconWhiteStroke.png"
                          alt=""
                        />
                      </picture>
                    </Link>
                    {/* <span className="blink text-danger d-block text-right">
                      ◉ LIVE
                    </span>
                    <Link to={"/snake-and-ladder"} className="gameCard">
                      <picture className="gameCard-image">
                        <img width="100%" src="../assest/image/11.png" alt="" />
                      </picture>
                      <div className="gameCard-title">Snake And Ladder</div>
                      <picture className="gameCard-icon">
                        <img
                          src="../assest/image/global-battleIconWhiteStroke.png"
                          alt=""
                        />
                      </picture>
                    </Link>
                    <span className="blink text-danger d-block text-right">
                      ◉ Comming Soon
                    </span> */}
                    {/* <a href={"#"} className="gameCard">
                      <picture className="gameCard-image">
                        <img
                          width="100%"
                          src="../assest/image/cricket.png"
                          alt=""
                        />
                      </picture>
                      <div className="gameCard-title">Cricket</div>
                      <picture className="gameCard-icon">
                        <img
                          src="../assest/image/global-battleIconWhiteStroke.png"
                          alt=""
                        />
                      </picture>
                    </a> */}
                    {/* <a
                      className="gameCard"
                      href=""
                    >
                      <picture className="gameCard-image">
                        <img
                          width="100%"
                          src=""
                          alt=""
                        />
                      </picture>
                      <div className="gameCard-title">Ludo Popular</div>
                      <picture className="gameCard-icon">
                        <img
                          src="../assest/image/global-battleIconWhiteStroke.png"
                          alt=""
                        />
                      </picture>
                    </a> */}
                  </div>
                  {/* <!--<div className="gameCard-container">
                        <span className="blink text-danger d-block text-right">◉ LIVE</span>
                        <a className="gameCard" href="#">
                            <picture className="gameCard-image">
                                <img width="100%" src="../assest/image/games/kb_ludo_classic.jpeg" alt="">
                            </picture>
                            <div className="gameCard-title">Ludo Popular</div>
                            <picture className="gameCard-icon">
                                <img src="../assest/image/global-battleIconWhiteStroke.png" alt="">
                            </picture>
                        </a>
                    </div>
    
    
                    <div className="gameCard-container">
                        <span className="blink text-danger d-block text-right">◉ Live</span>
                        <a className="gameCard" href="#">
                            <picture className="gameCard-image">
                                <img width="100%" src="../assest/image/games/kb_ludo.jpeg" alt="">
    
                            </picture>
                            <div className="gameCard-title">Ludo No Cut</div>
                            <picture className="gameCard-icon">
                                <img src="../assest/image/global-battleIconWhiteStroke.png" alt="">
                            </picture>
                        </a>
                    </div>
                    <div className="gameCard-container">
                        <span className="blink text-danger d-block text-right">◉ Live</span>
                        <a className="gameCard" href="#">
                            <picture className="gameCard-image">
                                <img width="100%" src="../assest/image/games/kb_ludo_classic.jpeg" alt="">
                            </picture>
                            <div className="gameCard-title">Ludo Ulta</div>
                            <picture className="gameCard-icon">
                                <img src="../assest/image/global-battleIconWhiteStroke.png" alt="">
                            </picture>
                        </a>
                    </div>
    
    
    
    
                    <div className="gameCard-container">
                        <span className="blink text-danger d-block text-right">◉ Comming Soon</span>
                        <a className="gameCard" href="#">
                            <picture className="gameCard-image">
                                <img width="100%" src="../assest/image/rummy.png" alt="">
    
                            </picture>
                            <div className="gameCard-title">Rummy</div>
                            <picture className="gameCard-icon">
                                <img src="../assest/image/global-battleIconWhiteStroke.png" alt="">
                            </picture>
                        </a>
                    </div>
                    <div className="gameCard-container">
                        <span className="blink text-danger d-block text-right">◉ Comming Soon</span>
                        <a className="gameCard" href="#">
                            <picture className="gameCard-image">
                                <img width="100%" src="../assest/image/teen.png" alt="">
                            </picture>
                            <div className="gameCard-title">Teen Patti</div>
                            <picture className="gameCard-icon">
                                <img src="../assest/image/global-battleIconWhiteStroke.png" alt="">
                            </picture>
                        </a>
                    </div>
    
    
                    <div className="gameCard-container">
                        <span className="blink text-danger d-block text-right">◉ Comming Soon</span>
                        <a className="gameCard" href="#">
                            <picture className="gameCard-image">
                                <img width="100%" src="../assest/image/games/fantasy-cricket.jpeg" alt="">
                            </picture>
                            <div className="gameCard-title">Snakes & Ladders</div>
                            <picture className="gameCard-icon">
                                <img src="../assest/image/global-battleIconWhiteStroke.png" alt="">
                            </picture>
                        </a>
                    </div>
                    <div className="gameCard-container">
                        <span className="blink text-danger d-block text-right">◉ Comming Soon</span>
                        <a className="gameCard" href="#">
                            <picture className="gameCard-image">
                                <img width="100%" src="../assest/image/games/unnamed.webp" alt="">
                            </picture>
                            <div className="gameCard-title">Dragon Tiger</div>
                            <picture className="gameCard-icon">
                                <img src="../assest/image/global-battleIconWhiteStroke.png" alt="">
                            </picture>
                        </a>
                    </div>
    --> */}
                </div>
              </div>
            </section>
            {/* <!------Main Content End------>

          <!------Footer Start------> */}
            <section className="footer">
              <div className="footer-divider"></div>
              <a
                className="px-3 py-4 d-block"
                href="#!"
                style={{ textDecoration: "none" }}
              >
                <picture className="">
                  <img
                    width="100px"
                    hight="20px"
                    src="../assest/image/vplay-logo.png"
                    alt=""
                  />
                </picture>
                <span
                  style={{
                    color: "rgb(149, 149, 149)",
                    fontSize: " 0.8em",
                    fontWeight: "400",
                  }}
                >
                  . Terms, Privacy, Support
                </span>
                <picture className="footer-arrow">
                  <img
                    width="21px"
                    src="../assest/image/global-grey-dropDown.png"
                    alt=""
                  />
                </picture>
              </a>
              <div
                className="px-3 overflow-hidden"
                style={{ height: "0px", transition: "height 0.5s ease 0s" }}
              >
                <div className="row footer-links">
                  <a className="col-6" href="/term-condition">
                    Terms &amp; Condition
                  </a>
                  <a className="col-6" href="/privacy-policy">
                    Privacy Policy
                  </a>
                  <a className="col-6" href="/refund-policy">
                    Refund/Cancellation Policy
                  </a>
                  <a className="col-6" href="/contact-us">
                    Contact Us
                  </a>
                  <a className="col-6" href="/responsible-gaming">
                    Responsible Gaming
                  </a>
                </div>
              </div>
              <div className="footer-divider"></div>
              <div className="px-3 py-4">
                <div className="footer-text-bold">About Us</div>
                <br />
                <div className="footer-text">
                Classic Adda Ludo  is a real-money gaming product owned and operated
                  by RP Gaming Limited (" Classic Adda Ludo " or "We" or "Us" or
                  "Our").
                </div>
                <br />
                <div className="footer-text-bold">
                  Our Business &amp; Products
                </div>
                <br />
                <div className="footer-text">
                  We are an HTML5 game-publishing company and our mission is to
                  make accessing games fast and easy by removing the friction of
                  app-installs.
                </div>
                <br />
                <div className="footer-text">
                Classic Adda Ludo  is a skill-based real-money gaming platform
                  accessible only for our users in India. It is accessible on
                  <a href="https://classicadda.in">
                  classicadda.in{" "}
                  </a>
                  . On Classic Adda Ludo , users can compete for real cash in
                  Tournaments and Battles. They can encash their winnings via
                  popular options such as Paytm Wallet, Amazon Pay, Bank
                  Transfer, Mobile Recharges etc.
                </div>
                <br />
                <div className="footer-text-bold">Our Games</div>
                <br />
                <div className="footer-text">
                Classic Adda Ludo  has a wide-variety of high-quality, premium HTML5
                  games. Our games are especially compressed and optimised to
                  work on low-end devices, uncommon browsers, and patchy
                  internet speeds.
                </div>
                <br />
                <div className="footer-text" style={{ marginBottom: "100px" }}>
                  We have games across several popular categories: Arcade,
                  Action, Adventure, Sports &amp; Racing, Strategy, Puzzle &amp;
                  Logic. We also have a strong portfolio of multiplayer games
                  such as Ludo, Chess, 8 Ball Pool, Carrom, Tic Tac Toe,
                  Archery, Quiz, Chinese Checkers and more! Some of our popular
                  titles are: Escape Run, Bubble Wipeout, Tower Twist, Cricket
                  Gunda, Ludo With Friends. If you have any suggestions around
                  new games that we should add or if you are a game developer
                  yourself and want to work with us, don't hesitate to drop in a
                  line at
                  <a href="info@classicadda.in">info@classicadda.in</a>!
                </div>
              </div>
            </section>
            <div className="kyc-select">
              <div className="overlay"></div>
              <div
                className="box"
                style={{ bottom: "0px", position: "absolute" }}
              >
                <div className="bg-white">
                  <div className="header" style={{ borderBottom: "unset" }}>
                    <div className="d-flex position-relative align-items-center">
                      <img
                        src="../assest/image/global-ytPlayIcon.png"
                        width="20px"
                        alt=""
                      />
                      <div className="games-section-title ms-3">
                        How to play on Classic Adda Ludo?
                      </div>
                      <span
                        className="position-absolute font-weight-bold cxy"
                        style={{ right: "5px", height: "40px", width: "40px" }}
                      >
                        X
                      </span>
                    </div>
                    <div className="tutorialVideo">
                      <div id="tabNav-1" className="tab tabActive">
                        <span>Hindi</span>
                        <div className="selectedLine"></div>
                      </div>
                      <div id="tabNav-2" className="tab">
                        <span>English</span>
                      </div>
                    </div>
                  </div>
                  <div style={{ paddingTop: "150px", paddingBottom: "60px" }}>
                    <div className="embed-responsive embed-responsive-16by9"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div class="refer-footer">
             
            <a
              class="disabledButton btn btn-success btn-lg installbtn"
              href="../assest/image/drludo.apk"
              download
            >
              <img
                width="21px"
                src="../assest/image/apk.png"
                alt=""
                style={{ marginRight: "25px" }}
              />
              Install
              <img
                width="21px"
                src="../assest/image/download.png"
                style={{ marginLeft: "25px" }}
                alt=""
              />
            </a>
          </div> */}
        </div>

        <div className="divider-y"></div>
        <RightContainer />
      </div>
    </div>
  );
}

export default Home;
